import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-classes"></a><h2>Psionic Classes
    </h2>
    <a id="wilder"></a><h3>WILDER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80450.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Any.
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6.</p>
    <h6>Class Skills</h6>
The wilder&#8217;s class skills (and the key ability for each skill) are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a>* (Wis), <a href="skillsAll.html#balance" style={{
      "color": "rgb(87, 158, 182)"
    }}>Balance</a>
(Dex), <a href="skillsAll.html#bluff" style={{
      "color": "rgb(87, 158, 182)"
    }}>Bluff</a>
(Cha), <a href="skillsAll.html#climb" style={{
      "color": "rgb(87, 158, 182)"
    }}>Climb</a>
(Str), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a>* (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#escape-artist" style={{
      "color": "rgb(87, 158, 182)"
    }}>Escape Artist</a> (Dex), <a href="skillsAll.html#intimidate" style={{
      "color": "rgb(87, 158, 182)"
    }}>Intimidate</a>
(Cha), <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump</a>
(Str), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a>* (Int), <a href="skillsAll.html#listen" style={{
      "color": "rgb(87, 158, 182)"
    }}>Listen</a>
(Wis), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a> (Wis), <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>*
(Int), <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense Motive </a>(Wis), <a href="skillsAll.html#spot" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spot</a>
(Wis), <a href="skillsAll.html#swim" style={{
      "color": "rgb(87, 158, 182)"
    }}>Swim</a>
(Str), and <a href="skillsAll.html#tumble" style={{
      "color": "rgb(87, 158, 182)"
    }}>Tumble</a> (Dex).
*New skill or expanded use of existing skill.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at 1st Level:</span>
(4 + Int modifier) x4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier.
    </p>
    <a id="table-the-wilder"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Wilder</span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="1" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="1" style={{
            "width": "34%"
          }}>Special</th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Power Points/Day</span>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span></td>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Maximum Power Level Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Wild surge +1, psychic enervation
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Elude touch
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Wild surge +2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Surging euphoria +1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>17
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Volatile mind (1 power point)
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Wild surge +3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>46
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>58
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Volatile mind (2 power points)
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>72
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2
          </td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>88
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>Wild surge +4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>106
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>Surging euphoria +2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>126
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>Volatile mind (3 power points)
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>147
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6th
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>170
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7th
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
          <td>Wild surge +5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>195
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>7th
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>221
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8th
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>Volatile mind (4 power points)
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>250
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>8th
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>280
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>Wild surge +6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>311
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>10
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">Surging euphoria +3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>343
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>11
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
        </tr>
      </tbody>
    </table>
    <a id="wilder-class-features"></a><h6>Class Features</h6>
All the following are class features of the wilder.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Wilders are proficient with all simple weapons, with light armor, and
with shields (except tower shields).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Power Points/Day:</span> A
wilder&#8217;s ability to manifest powers is limited by the power points she
has available. Her base daily allotment of power points is given on
Table: The Wilder. In addition, she receives bonus power points per day
if she has a high Charisma score (see Table: Ability Modifiers and
Bonus Power Points). Her race may also provide bonus power points per
day, as may certain feats and items.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> A wilder
begins play knowing one wilder power of your choice. At every
even-numbered class level after 1st, she unlocks the knowledge of new
powers.
Choose the powers known from the <a href="PsionicPsionList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>wilder power list</a>. (Exception:
The
feats <a href="psionicFeats.html#expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Expanded Knowledge</a> and <a href="epicFeats.html#epic-expanded-knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Epic Expanded Knowledge</a> do allow
a wilder
to learn powers from the lists of other classes.) A wilder can manifest
any power that has a power point cost equal to or lower than her
manifester level.
The total number of powers a wilder can manifest in a day is limited
only by her daily power points.
A wilder simply knows her powers; they are ingrained in her mind. She
does not need to prepare them (in the way that some spellcasters
prepare their spells), though she must get a good night&#8217;s sleep each
day to regain all her spent power points.
The Difficulty Class for saving throws against wilder powers is 10 +
the power&#8217;s level + the wilder&#8217;s Charisma modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Maximum Power Level Known:</span>
A wilder begins play with the ability to learn 1st-level powers. As she
attains higher levels, she may gain the ability to master more complex
powers.
To learn or manifest a power, a wilder must have a Charisma score of at
least 10 + the power&#8217;s level.</p>
    <a id="wilder-wild-surge"></a><p><span style={{
        "fontWeight": "bold"
      }}>Wild Surge (Su):</span> A
wilder can let her passion and emotion rise to the surface in a wild
surge when she manifests a power. During a wild surge, a wilder gains
phenomenal psionic strength, but may harm herself by the reckless use
of her power (see Psychic Enervation, below).
A wilder can choose to invoke a wild surge whenever she manifests a
power. When she does so, she gains +1 to her manifester level with that
manifestation of the power. The manifester level boost gives her the
ability to augment her powers to a higher degree than she otherwise
could; however, she pays no extra power point for this wild surge.
Instead, the additional 1 power point that would normally be required
to augment the power is effectively supplied by the wild surge.
Level-dependent power effects are also improved, depending on the power
a wilder manifests with her wild surge.
This improvement in manifester level does not grant her any other
benefits (psicrystal abilities do not advance, she does not gain
higher-level class abilities, and so on).
She cannot use the <a href="psionicFeats.html#overchannel" style={{
        "color": "rgb(87, 158, 182)"
      }}>Overchannel</a> psionic feat and
invoke her wild surge
at the same time.
At 3rd level, a wilder can choose to boost her manifester level by two
instead of one. At 7th level, she can boost her manifester level by up
to three; at 11th level, by up to four; at 15th level, by up to five;
and at 19th level, by up to six.
In all cases, the wild surge effectively pays the extra power point
cost that is normally required to augment the power; only the
unaugmented power point cost is subtracted from the wilder&#8217;s power
point reserve.</p>
    <a id="wilder-psychic.enervation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychic Enervation (Ex):</span>
Pushing oneself by invoking a wild surge is dangerous. Immediately
following each wild surge, a wilder may be overcome by the strain of
her effort. The chance of suffering psychic enervation is equal to 5%
per manifester level added with the wild surge.
A wilder who is overcome by psychic enervation is <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a> until the end
of her next turn and loses a number of power points equal to her wilder
level.</p>
    <a id="wilder-elude-touch"></a><p><span style={{
        "fontWeight": "bold"
      }}>Elude Touch (Ex):</span>
Starting at 2nd level, a wilder&#8217;s intuition supersedes her intellect,
alerting her to danger from touch attacks (including rays). She gains a
bonus to Armor Class against all touch attacks equal to her Charisma
bonus; however, her touch AC can never exceed her Armor Class against
normal attacks.</p>
    <a id="wilder-surgic-euphoria"></a><p><span style={{
        "fontWeight": "bold"
      }}>Surging Euphoria (Ex):</span>
Starting at 4th level, when a wilder uses her wild surge ability, she
gains a +1 morale bonus on attack rolls, damage rolls, and saving
throws for a number of rounds equal to the intensity of her wild surge.
If a wilder is overcome by psychic enervation following her wild surge,
she does not gain the morale bonus for this use of her wild surge
ability.
At 12th level, the morale bonus on a wilder&#8217;s attack rolls, damage
rolls, and saving throws increases to +2. At 20th level, the bonus
increases to +3.</p>
    <a id="wilder-volatile-mind"></a><p><span style={{
        "fontWeight": "bold"
      }}>Volatile Mind (Ex):</span> A
wilder&#8217;s temperamental mind is hard to encompass with the discipline of
telepathy. When any telepathy power is manifested on a wilder of 5th
level or higher, the manifester of the power must pay 1 power point
more than he otherwise would have spent.
The extra cost is not a natural part of that power&#8217;s cost. It does not
augment the power; it is simply a wasted power point. The wilder&#8217;s
volatile mind can force the manifester of the telepathy power to exceed
the normal power point limit of 1 point per manifester level. If the
extra cost raises the telepathy power&#8217;s cost to more points than the
manifester has remaining in his reserve, the power simply fails, and
the manifester exhausts the rest of his power points.
At 9th level, the penalty assessed against telepathy powers manifested
on a wilder is increased to 2 power points. At 13th level, the penalty
increases to 3 power points, and at 17th level it increases to 4 power
points.
As a standard action, a wilder can choose to lower this effect for 1
round.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      